import { Network } from 'network';
import { ElectronService } from 'ngx-electron';
import { TranslateService } from '@ngx-translate/core';
import { HybridsSocketService } from 'hybrids-socket';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DatabaseService, RepositoryService } from 'sqlite';
import { VersionBoxService } from 'version-box';
import { ColorService,
         ScenarioService,
         CommonTeacherService,
         HeaderTeacherService,
         IpcTeacherService,
         TemplateTeacherService,
         Distribution,
         SectionType,
         MonitorTeacherService,
         LanguageTeacherService,
         SocketListenerTeacherService } from 'hvita';
import { ReceiverService } from 'hvita-receiver';
import { AppBase, ErrorType } from 'hybrids-config';
import { HttpManagerService } from 'http-manager';
import { WaveTeacherService } from 'patient-monitor';

/**
 * Componente que representa la base de la aplicación
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends AppBase
{
  environment = environment;

  constructor(private router: Router,
              private languageService: LanguageTeacherService,
              private databaseService:DatabaseService,
              private repositoryService: RepositoryService,
              private hybridsSocketService:HybridsSocketService,
              private colorService:ColorService,
              private receiverService: ReceiverService,
              private versionBoxService:VersionBoxService,
              private httpManagerService:HttpManagerService,
              private socketListenerService: SocketListenerTeacherService,
              private templateService:TemplateTeacherService,
              private commonService:CommonTeacherService,
              private monitorService:MonitorTeacherService,
              private waveService:WaveTeacherService,
              private headerService:HeaderTeacherService,
              private scenarioService: ScenarioService,
              private translateService: TranslateService,
              public ipcService: IpcTeacherService,
              public electronService:ElectronService)
  {
    super();
  }

  /**
   * Ejecuta los procesos de inicialización de la aplicación
   * y añade las subscripciones necesarias para responder a
   * diversas acciones emitidas desde distintos componentes
   */
  override async initialize()
  {
    try
    {
      //distribución visual de los paneles de simulación
      const distribution = new Array<Distribution>(
        new Distribution({
          section: SectionType.ECMO_PREASURE,
          title : 'ecmo',
          keys : [['lpm', 'rpm'], ['pven', 'part'], ['ap', 'tven']]
        }),
        new Distribution({
          section: SectionType.ECMO_FLOW,
          title : 'ecmo',
          keys : [['svo2', 'hbhc'], ['act', ''], ['gasflow', 'gasfio2']]
        }),
        new Distribution({
          section: SectionType.VENTILATION,
          title : 'respirador',
          keys : [['frpautado', 'peep', 'frtotal'], ['fio2', 'vtespirado', 'pplat'], ['vtpautado', 'ps', 'ie'], ['pins', 'ppeak', '']]
        }),
        new Distribution({
          section: SectionType.NEURO,
          title : 'neuro',
          keys : [['bis', 'nirs']]
        }),
        new Distribution({
          section: SectionType.MONITOR,
          title : 'monitor',
          keys : [['ecg', 'abp', 'pap', 'spo2', 'cvp']]
        }),
        new Distribution({
          section: SectionType.CONTROLLER,
          title : 'controlador',
          keys : [['p3', 'p1'], ['preox', 'postox'], ['bubble', 'bleed']]
        })
      );

      this.httpManagerService.enableLoading(false);

      //establecemos un idioma por defecto
      this.translateService.use(environment.defaultLanguage);

      this.receiverService.initialize();

      //es importante mantener el orden de inicialización
      await this.ipcService.initializeData();

      this.versionBoxService.setVersion(this.ipcService.info.software, this.ipcService.info.hardware);

      this.repositoryService.setDatabase(await this.databaseService.openConnection());

      await this.languageService.initializeLanguages();
      await this.colorService.initializeColors();
      await this.scenarioService.initializeScenario();
      await this.templateService.initializeTemplates(distribution);
      await this.monitorService.initializeMonitor(distribution.find(m => m.section === SectionType.MONITOR).keys[0]);

      this.waveService.initializeWaves();
      this.commonService.initializeCommon();
      this.headerService.initializeHeader();

      //inicializamos el socket y el listener
      this.hybridsSocketService.initialize(environment.endPointSocket, environment.device);
      this.socketListenerService.initialize();

      //configuración inicial de la red wifi
      await Network.initialize(this.ipcService.info.ssid!, this.ipcService.info.password!, this.ipcService.localStorageWireless);

      if(this.electronService.isElectronApp) //en aplicaciomnes electron iniciamos el checkeo de redes
        await Network.check();
      else //en aplicaciones web abrimos la conexión socket
        this.hybridsSocketService.openConnection();
    }
    catch(err)
    {
      this.router.navigate(['/', 'error', ErrorType.UNKNOW])
    }
  }
}
