import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'loading',
    pathMatch: 'full'
  },
  {
    path: 'loading',
    loadChildren: () => import('./pages/loading/loading.module').then( m => m.LoadingPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'error/:type',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('./pages/configuration/configuration.module').then( m => m.ConfigurationPageModule)
  },
  {
    path: 'scenarios/list/:from',
    loadChildren: () => import('./pages/scenarios/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'scenarios/menu',
    loadChildren: () => import('./pages/scenarios/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'scenarios/manage/:from/:scenario/:stage',
    loadChildren: () => import('./pages/scenarios/manage/manage.module').then( m => m.ManagePageModule)
  },
  {
    path: 'scenarios/manage/:from/:flist/:scenario/:stage',
    loadChildren: () => import('./pages/scenarios/manage/manage.module').then( m => m.ManagePageModule)
  },
  {
    path: 'scenarios/import-export/menu',
    loadChildren: () => import('./pages/scenarios/import-export/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'scenarios/import-export/import',
    loadChildren: () => import('./pages/scenarios/import-export/import/import.module').then( m => m.ImportPageModule)
  },
  {
    path: 'scenarios/import-export/export',
    loadChildren: () => import('./pages/scenarios/import-export/export/export.module').then( m => m.ExportPageModule)
  },
  {
    path: 'simulation',
    loadChildren: () => import('./pages/simulation/simulation.module').then( m => m.SimulationPageModule)
  },
  {
    path: 'simulation/:scenario/:stage',
    loadChildren: () => import('./pages/simulation/simulation.module').then( m => m.SimulationPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'calibration',
    loadChildren: () => import('./pages/calibration/calibration.module').then( m => m.CalibrationPageModule)
  },
  {
    path: 'wireless',
    loadChildren: () => import('./pages/wireless/wireless.module').then( m => m.WirelessPageModule)
  },

  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
